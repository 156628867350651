<template>
    <el-container style="height:100%">
        <el-aside width="400px" style="padding:5px">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText"></el-input>
            <el-tree ref="tree"
                     :data="treeData"
                     :props="defaultProps"
                     node-key="id"
                     @node-click="handleNodeClick"
                     :default-expanded-keys="expanded"
                     :filter-node-method="filterNode"
                     highlight-current>
                <template slot-scope="{ node, data }">
                    <span :class="data.icon">
                        {{data.name}}({{data.subNodes ? data.subNodes.length : 0}})
                    </span>
                </template>
            </el-tree>
        </el-aside>
        <el-container>
            <el-header style="height: 45px;line-height: 45px;padding: 0;display: flex">
                <div>
                    <el-button type="primary" size="small" @click="handleSave" icon="el-icon-document-add">保 存</el-button>
                    <el-button type="primary" size="small" @click="handleAddFirst" icon="el-icon-plus">添加顶级</el-button>
                    <el-button v-if="nextShow" type="primary" size="small" @click="handleNext" icon="el-icon-plus">添加下级
                    </el-button>
                </div>
                <el-popconfirm style="margin-left:10px;" v-if="nextShow" title="删除当前节点和所有孙节点，数据不可恢复，请确认是否删除"
                               @confirm="handleDel">
                    <el-button slot="reference" type="danger" size="small" icon="el-icon-delete">删除当前菜单和所有子孙节点
                    </el-button>
                </el-popconfirm>
                <el-popconfirm style="margin-left:10px;" v-if="nextShow" :title="'是否变更当前菜单至->' + changeMenu.name"
                               @confirm="handleChangeMenu">
                    <el-button slot="reference" size="small" type="warning">变更菜单节点</el-button>
                </el-popconfirm>
                <div v-if="nextShow" style="width:200px;display: flex;margin-left: 10px">
                    <div>目标父菜单：</div>
                    <x-tree-selector :treeService="targetTreeSevice" v-model="changeMenu.id" size="small"
                                     @selected="changeMenuSelected" :name.sync="changeMenu.name"
                                     :sels="changeMenu.id"></x-tree-selector>
                </div>
            </el-header>
            <el-main style="padding-right:5px;">
                <el-form ref="form" :model="form" :rules="rules" class="x-edit" style="white-space: normal">
                    <el-form-item label="菜单名称" prop="name">
                        <el-input v-model="form.name" size="small" maxlength="30" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="URL" prop="url">
                        <el-input v-model="form.url" size="small" maxlength="80" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="BINDING" prop="binding">
                        <el-input v-model="form.binding" size="small" maxlength="80" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="图标" prop="icon">
                        <el-input v-model="form.icon" size="small" maxlength="40" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="数据密级" prop="classification">
                        <x-selector-one v-model="form.classification"
                                       dictType="T_SECURITY_CLASSIFICATION"></x-selector-one>
                    </el-form-item>
                    <el-form-item label="树结构层级" prop="level">
                        <el-input v-model="form.level" size="small" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="排序号" prop="sort">
                        <el-input-number v-model.number="form.sort" size="small" :min=0 :max=1000
                                         :step=1></el-input-number>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <x-switch v-model="form.status" dictType="T_EFFECTIVE"/>
                    </el-form-item>
                    <el-form-item label="是否为快捷操作" prop="operLink">
                        <x-switch v-model="form.operLink" dictType="T_YES_OR_NO"/>
                    </el-form-item>
                    <el-form-item label="描述" prop="description" class="line">
                        <el-input type="textarea" v-model="form.description" size="small" maxlength="150"
                                  show-word-limit/>
                    </el-form-item>
                </el-form>
                <el-divider content-position="center">模块方法</el-divider>
                <div class="page-btn">
                    <el-button type="primary" round size="mini" @click="recognitionJson">识别</el-button>
                    <el-button round size="mini" @click="recognitionJsonClean">清空</el-button>
                </div>
                <el-input v-model="recognition" type="textarea" placeholder="请输入识别信息"
                          :autosize="{ minRows: 4, maxRows: 4 }"></el-input>
                <el-form :model="form" :rules="menuFunctionRules" ref="menuFunctionsForm">
                    <x-row :rows="form.menuFunctions" :rowModel="defaultFunctionModel">
                        <x-row-cell title="名称" width="380" prop="name">
                            <template #default="{row}">
                                <el-input size="small" v-model="row.name"></el-input>
                            </template>
                        </x-row-cell>
                        <x-row-cell title="URL" width="480" prop="methodUrl">
                            <template #default="{row}">
                                <el-input size="small" v-model="row.methodUrl"></el-input>
                            </template>
                        </x-row-cell>
                        <x-row-cell title="操作类型" width="180" prop="operateType">
                            <template #default="{row}">
                                <x-selector-one v-model="row.operateType" size="small" dictType="T_OPERATE"/>
                            </template>
                        </x-row-cell>
                        <!--                        <x-row-cell title="排序号" width="180" prop="sort">-->
                        <!--                            <template #default="{row}">-->
                        <!--                                <el-input-number v-model.number="row.sort" size="small" :min=0 :max=1000-->
                        <!--                                                 :step=1></el-input-number>-->
                        <!--                            </template>-->
                        <!--                        </x-row-cell>-->
                        <x-row-cell title="功能密级" width="100" prop="functionClassification">
                            <template #default="{row}">
                                <x-selector-one v-model="row.functionClassification" size="small"
                                                dictType="T_SECURITY_CLASSIFICATION"/>
                            </template>
                        </x-row-cell>
                    </x-row>
                </el-form>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import {ruleBuilder} from '@/util/validate';
    import * as service from '@/service/system/menu';
    import XTreeBase from "@/components/x/XTreeBase";
    import {deepCopy} from '@/util/objects';
    import {v4} from 'uuid';

    export default {
        name: "Menu",
        mixins: [XTreeBase],
        beforeCreate() {
            let {required, max} = ruleBuilder;
            this.targetTreeSevice = service.mainTree;
            this.treeService = service.mainTree;
            this.addService = service.add;
            this.updateService = service.update;
            this.delService = service.del;
            this.defaultModel = {
                id: "",
                parentId: "0",
                name: "",
                level: 1,
                sort: 0,
                icon: "",
                url: "",
                binding: "",
                status: "true",
                operLink: "false",
                description: "",
                classification: "0",
                menuFunctions: []
            };
            this.defaultFunctionModel = {
                name: "",
                methodUrl: "",
                operateType: "",
                sort: 0,
                functionClassification: "0"
            }
            this.rules = {
                name: [required()],
                level: [required()],
                sort: [required('number')],
                url: [required('string'), max(80, 'string')],
                binding: [max(80, 'string')],
                icon: [max(40, 'string')],
                classification: [required('string')],
                description: [max(150, 'string')],
            }
            this.menuFunctionRules = {
                name: [required(), max(20, 'string')],
                methodUrl: [required(), max(50, 'string')],
                operateType: [required('string', 'change')],
                sort: [required('number'), max(50)],
                functionClassification: [required('string', 'change')],
            };
        },
        data() {
            return {
                changeMenu: {
                    name: "",
                    id: "",
                },
                recognition: "",
                form: deepCopy(this.defaultModel)
            };
        },
        methods: {
            handleRefreshTree() {
                this.defaultRefreshTree().then((response) => {
                    // when success do something
                }).catch((error) => {
                    // when error do something
                })
            },
            handleNodeClick(data) {
                this.defaultNodeClick(data);
                if (this.form.id) {
                    service.getUpdate(this.form.id).then((response) => {
                        this.form.menuFunctions = response.data.menuFunctions || [];
                    }).catch((error) => {
                        // when error do something
                    })
                }
                // do something
            },
            async handleSave() {
                try {
                    await this.$refs.form.validate();
                    await this.$refs.menuFunctionsForm.validate();
                } catch (e) {
                    return;
                }

                let api = this.isUpdate ? this.updateService : this.addService;
                const response = await api(this.form);//执行保存方法
                //打印提示信息
                this.$message.success(response.msg);
                this.nextShow = true;
                this.form.id = response.data;
                this.expanded = [this.form.id];
                this.isUpdate = true;

                this.handleRefreshTree();
            },
            handleAddFirst() {
                this.defaultToAdd();
            },
            handleNext() {
                this.defaultNext();
            },
            handleDel() {
                this.defaultDel().then((response) => {
                    this.handleRefreshTree();
                    this.defaultToAdd();
                    // when success do something
                }).catch((error) => {
                    // when error do something
                })
            },
            //识别json,产生菜单子表
            recognitionJson() {
                var arr = JSON.parse(this.recognition);
                arr.forEach((obj) => obj._key = v4());
                this.form.menuFunctions = this.form.menuFunctions.concat(arr);
            },
            recognitionJsonClean() {
                this.recognition = "";
            },
            changeMenuSelected(datas) {
                let menu = [];
                if (datas && datas.length > 0) {
                    menu = datas[0];
                }
                this.changeMenu.id = menu.id || "";
                this.changeMenu.name = menu.name || "";
            },
            handleChangeMenu() {
                if (!this.changeMenu.id) {
                    this.$message.warning("请选择一个目标菜单");
                    return false;
                }
                if (!this.form.id) {
                    this.$message.warning("请选择一个有效菜单");
                    return false;
                }
                service.changeMenuParent({id: this.form.id, parentId: this.changeMenu.id}).then((response) => {
                    this.$message.info(response.msg);
                    this.handleRefreshTree();
                    this.expanded = [this.form.id];
                });
            }
        }
    };
</script>

<style scoped>

</style>
